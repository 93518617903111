import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, message, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { i18n } from 'translations';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import config from 'config';
import Routes from 'config/routes';
import { useGenerateShiftReportPdfsMutation } from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import { downloadFile } from 'technical/print';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { DateRangeType } from 'technical/types';
import { requiredErrorAlert } from 'technical/validation/rules';
import CapCard from 'ui/CapCard/CapCard';
import Flex from 'ui/flex';

import './index.scss';

const { RangePicker } = DatePicker;

interface FormValues {
  constructionSiteId?: string;
  picker?: any;
  range?: any;
}

function ReportExportBatchPage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();

  const [generationPending, setGenerationPending] = useState<boolean>(false);
  const [generatePdf] = useGenerateShiftReportPdfsMutation();
  const [dates, setDates] = useState<DateRangeType>(null);

  const disabledDate = (current: Dayjs) => {
    const MAX_RANGE = 7;
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > MAX_RANGE - 1;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > MAX_RANGE - 1;
    return tooEarly || tooLate;
  };

  const onFinish = async (values: FormValues) => {
    try {
      if (!values.range[0] || !values.range[1]) {
        throw new Error('missing fields');
      }
      setGenerationPending(true);
      // Search for each report with current constructionSite, in values.date
      const generatedZip = await generatePdf({
        variables: {
          constructionSiteId: currentConstructionSite?.id,
          startDate: values.range[0].format(SIMPLE_DATE_FORMAT),
          endDate: values.range[1].format(SIMPLE_DATE_FORMAT),
          language: i18n.language,
        },
      });
      if (!generatedZip.data?.generateShiftReportPdfs?.success) {
        message.error(t('pages.manager.batchReport.getReportFail'));
      }

      if (
        generatedZip.data?.generateShiftReportPdfs?.success &&
        !generatedZip.data?.generateShiftReportPdfs?.url
      ) {
        message.warning(t('pages.manager.batchReport.noFile'));
      }

      if (generatedZip.data?.generateShiftReportPdfs?.url) {
        message.success(t('pages.manager.batchReport.getReportSuccess'));
        downloadFile(
          `${config.gcp.publicUri}/${generatedZip.data?.generateShiftReportPdfs.url}`,
          generatedZip.data?.generateShiftReportPdfs.url.split('/').pop(),
        );
      }
    } catch (newTaskError) {
      message.error(t('pages.manager.batchReport.getReportFail'));

      if (newTaskError instanceof Error) {
        errorReporting.error(newTaskError);
      }
    } finally {
      setGenerationPending(false);
    }
  };

  return (
    <div className="cap-batch-report-page main-bg-image page-appear">
      <CapCard title={t('pages.manager.batchReport.title')}>
        <Form
          name="basic"
          layout="vertical"
          size="middle"
          onFinish={onFinish}
          onFinishFailed={requiredErrorAlert}
        >
          {/* Periodic / Individual ShiftReport ZIP */}
          <Form.Item noStyle>
            <Form.Item
              label={t('pages.form.date.label')}
              name="range"
              rules={[{ required: true, message: t('pages.form.date.alert') }]}
            >
              <RangePicker
                disabledDate={disabledDate as (current: any) => boolean}
                className="input"
                picker="date"
                format="L"
                onCalendarChange={(values) => setDates(values)}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item className="button-center">
            <Button
              type="primary"
              htmlType="submit"
              icon={<DownloadOutlined />}
              loading={generationPending}
            >
              {t('pages.manager.batchReport.generate')}
            </Button>
          </Form.Item>
          <Flex justify="center">
            <Typography.Text>
              {generationPending
                ? t('pages.manager.batchReport.generateNotice')
                : null}
            </Typography.Text>
          </Flex>
        </Form>
        <Link to={{ pathname: Routes.ReportDashboard, search: '?tab=analyze' }}>
          <Button className="button-center">{t('common.to_main_page')}</Button>
        </Link>
      </CapCard>
    </div>
  );
}

export default ReportExportBatchPage;
