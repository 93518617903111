import { ExportOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  useIsConstructionSiteManager,
  usePermissions,
} from 'business/contextProviders/usePermissions';
import { ConstructionSitePlanningFilter } from 'business/production-and-performance/components/construction-site-planning-filters';
import config from 'config';
import { usePlanningControllerGeneratePlanningExport } from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';
import { downloadFile } from 'technical/print';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import Button from 'ui/button';

import { ManagerControls } from './manager-controls';

interface PlanningHeaderProps {
  filters: ConstructionSitePlanningFilter;
}

export const PlanningHeader = ({ filters }: PlanningHeaderProps) => {
  const { t } = useTranslation();
  const { isDataManager } = usePermissions();
  const isManager = !!useIsConstructionSiteManager(Module_Enum.ProdPerf);

  const [messageApi, contextHolder] = useNotification();

  const { currentConstructionSiteId } = useAppContext();
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const { mutate: generateExport, isPending: isExportPending } =
    usePlanningControllerGeneratePlanningExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        messageApi.error({
          message: t('errors.export_download_error'),
        });
      },
    });

  const [startDate, endDate] = filters.values ?? [null, null];

  const onClickExport = () => {
    generateExport({
      body: {
        constructionSiteId: currentConstructionSiteId,
        startDate: startDate ? startDate.format(SIMPLE_DATE_FORMAT) : undefined,
        endDate: endDate ? endDate.format(SIMPLE_DATE_FORMAT) : undefined,
        withProjection: filters.withProjection,
        locale: getLocale(),
      },
    });
  };

  return (
    <Space>
      {contextHolder}
      <Button
        icon={<ExportOutlined />}
        iconPosition="end"
        loading={isExportPending}
        onClick={onClickExport}
      >
        {t('common.export')}
      </Button>
      {isManager || isDataManager ? (
        <ManagerControls constructionSiteId={currentConstructionSiteId} />
      ) : null}
    </Space>
  );
};
