import React from 'react';
import { Navigate } from 'react-router-dom';

import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';

import { useAppContext } from './contextProviders/useAppContext';

interface MultiModuleRouteProps {
  redirectPath?: string;
  children: React.ReactNode;
}

const MultiModuleRoute: React.FC<MultiModuleRouteProps> = ({
  redirectPath = Routes.Home,
  children,
}) => {
  const { currentConstructionSite } = useAppContext();

  if (!currentConstructionSite) {
    return <Navigate to={Routes.NO_CONSTRUCTION_SITE} />; // should be handled by router cf: noConstructionSite
  }

  const { userPermissions } = currentConstructionSite;

  // Filter out the Backoffice module, as it shouldn't count
  const filteredPermissions = userPermissions.filter(
    (permission) => permission.module !== Module_Enum.Backoffice,
  );

  // Check if the remaining modules only include ShiftReport
  const hasShiftReportOnly =
    filteredPermissions.length === 1 &&
    filteredPermissions[0].module === Module_Enum.ShiftReport;

  if (hasShiftReportOnly) {
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};
export default MultiModuleRoute;
