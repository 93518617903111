import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { DEFAULT_NOT_CONNECTED_ROUTE } from 'business/protectedRouteContainer';

import ShiftPage from './shift-page';

export const ShiftProtectedPage: React.FC = () => {
  const { isConnected } = useAppContext();
  if (!isConnected) {
    return <Navigate to={DEFAULT_NOT_CONNECTED_ROUTE} />;
  }

  return (
    <ShiftPage>
      <Outlet />
    </ShiftPage>
  );
};
