import { PlotterMode } from 'business/data-analysis/constants';
import {
  formatGraphByPeriodData,
  formatGraphByRingData,
  removeGraphSetParameterValues,
} from 'business/data-analysis/services/format-graph-data';
import {
  useDataAnalysisControllerGraphByPeriod,
  useDataAnalysisControllerGraphByRing,
} from 'generated/apiComponents';
import { GraphStateDto } from 'generated/apiSchemas';

import { useQueryParams } from './use-query-params';

export const useGetGraphValues = (
  queryParams: ReturnType<typeof useQueryParams>,
) => {
  const {
    mutateAsync: fetchGraphValuesByPeriod,
    isPending: isFetchingGraphValuesByPeriod,
  } = useDataAnalysisControllerGraphByPeriod();

  const {
    mutateAsync: fetchGraphValuesByRing,
    isPending: isFetchingGraphValuesByRing,
  } = useDataAnalysisControllerGraphByRing();

  const getUpdatedGraphByMode = async (
    mode: PlotterMode,
    graphList: GraphStateDto[],
  ) => {
    if (mode === PlotterMode.TIME_PERIOD) {
      const value = await fetchGraphValuesByPeriod(
        queryParams.queryParamsForGraphByPeriod,
      );
      if (!value) {
        return null;
      }

      return formatGraphByPeriodData(
        value,
        removeGraphSetParameterValues(graphList),
      );
    }

    if (mode === PlotterMode.RING) {
      const graphByRingResponse = await fetchGraphValuesByRing(
        queryParams.queryParamsForGraphByRing,
      );
      if (!graphByRingResponse) {
        return null;
      }

      return formatGraphByRingData(
        graphByRingResponse,
        removeGraphSetParameterValues(graphList),
      );
    }

    return null;
  };

  return {
    getUpdatedGraphByMode,
    isFetching: isFetchingGraphValuesByPeriod || isFetchingGraphValuesByRing,
  };
};
