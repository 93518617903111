import {
  ProgressHistoryControllerProgressHistoryQueryParams,
  useProgressHistoryControllerProgressHistory,
} from 'generated/apiComponents';

export const useProgressHistory = ({
  startDate,
  endDate,
  constructionSiteId,
}: ProgressHistoryControllerProgressHistoryQueryParams) => {
  const {
    data: progressHistory,
    isFetching,
    error,
  } = useProgressHistoryControllerProgressHistory({
    queryParams: {
      startDate,
      endDate,
      constructionSiteId,
    },
  });

  return { error, loading: isFetching, progressHistory };
};
