import dayjs from 'dayjs';

import { FULL_LOCALIZED_DATE_AND_TIME_FORMAT } from 'technical/string/formatter';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

export const parseRingTimeToDate = (date: string | null | undefined) => {
  if (isUndefinedOrNull(date)) {
    return date;
  }

  return dayjs(date);
};

export function parseRingTimeToLocalizedString(
  dateString: string | null | undefined,
) {
  if (isUndefinedOrNull(dateString)) {
    return '-';
  }

  const date = dayjs(dateString).tz();
  if (!date.isValid()) {
    return '-';
  }

  return date.format(FULL_LOCALIZED_DATE_AND_TIME_FORMAT);
}
