import { DatePicker, message, Result, Select } from 'antd';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import ReportList, {
  DEFAULT_PAGE_LIMIT,
  DEFAULT_PAGE_NUMBER,
} from 'business/report/components/ReportList';
import Routes from 'config/routes';
import {
  Maybe,
  Scalars,
  ShiftReport_Bool_Exp,
  useGetManagerReportHistoryQuery,
} from 'generated/graphql';
import useSearchParamQuery from 'technical/router/hooks/useSearchParamQuery';
import { formatUserName } from 'technical/string/formatter';
import Button from 'ui/button';
import Flex from 'ui/flex';
import SectionTitle from 'ui/title/sectionTitle';

import styles from './index.module.scss';

const { RangePicker } = DatePicker;

const mapList = ({
  id,
  name,
}: {
  id: Scalars['uuid']['output'];
  name: string;
}) => (
  <Select.Option key={id} value={id}>
    {name}
  </Select.Option>
);

const userMapList = ({
  id,
  ...rest
}: {
  id: Scalars['uuid']['output'];
  email: string;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
}) => (
  <Select.Option value={id} key={id}>
    {formatUserName(rest)}
  </Select.Option>
);

function buildBooleanFilter<Keys extends string>(
  filters: Array<[key: Keys, value: string | undefined]>,
): Partial<Record<Keys, { _eq: string }>> {
  return Object.fromEntries(
    filters
      .map(([key, value]) =>
        value === undefined ? undefined : [key, { _eq: value }],
      )
      .filter(Boolean) as Array<[string, { _eq: string }]>,
  ) as Partial<Record<Keys, { _eq: string }>>;
}

function ReportListValidated() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const searchParamsQuery = useSearchParamQuery();
  const [dateStart, setDateStart] = useState<Dayjs | null>(null);
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(null);
  const [operatorFilter, setOperatorFilter] = useState<string>();
  const [shiftLeaderFilter, setShiftLeaderFilter] = useState<string>();
  const [shiftEngineerFilter, setShiftEngineerFilter] = useState<string>();
  const [shiftManagerFilter, setShiftManagerFilter] = useState<string>();
  const [shift, setShift] = useState<string>();

  const isManagerRequired = currentConstructionSite?.isManagerRequiredInReport;
  const isEngineerRequired =
    currentConstructionSite?.isEngineerRequiredInReport;

  const filter = useMemo(() => {
    const object: ShiftReport_Bool_Exp = buildBooleanFilter([
      ['operatorId', operatorFilter],
      ['shiftLeaderId', shiftLeaderFilter],
      ['shiftEngineerId', shiftEngineerFilter],
      ['shiftManagerId', shiftManagerFilter],
      ['shiftId', shift],
    ]);

    if (dateStart && dateEnd) {
      object.date = { _gte: dateStart, _lte: dateEnd };
    }

    return object;
  }, [
    operatorFilter,
    shift,
    shiftLeaderFilter,
    shiftEngineerFilter,
    shiftManagerFilter,
    dateStart,
    dateEnd,
  ]);

  const { data, loading, error, refetch } = useGetManagerReportHistoryQuery({
    variables: {
      limit: DEFAULT_PAGE_LIMIT,
      offset:
        (Number(searchParamsQuery.get('page') ?? DEFAULT_PAGE_NUMBER) - 1) *
        DEFAULT_PAGE_LIMIT,
      constructionSiteId: currentConstructionSite?.id,
      filter,
    },
    fetchPolicy: 'network-only',
    skip: !currentConstructionSite?.id,
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  const resetFilters = () => {
    setDateStart(null);
    setDateEnd(null);
    setOperatorFilter(undefined);
    setShiftLeaderFilter(undefined);
    setShiftEngineerFilter(undefined);
    setShiftManagerFilter(undefined);
    setShift(undefined);
  };

  const emptyListComponent = (
    <Result
      status="404"
      title={t('pages.report.list.filterNotFound.title')}
      subTitle={
        dateStart === null || dateEnd === null
          ? t('pages.report.list.noReport.label')
          : t('pages.report.list.filterNotFound.label')
      }
    />
  );

  return (
    <div className={classNames(styles.reportListValidatedPage)}>
      <SectionTitle
        label={t('pages.manager.history.title')}
        className={styles.title}
        leftButton={
          <Link
            to={{ pathname: Routes.ReportDashboard, search: '?tab=report' }}
          >
            <Button>{t('common.to_main_page')}</Button>
          </Link>
        }
      />
      <Flex
        className={classNames(styles.optionsContainer)}
        justify="space-around"
        wrap="wrap"
      >
        <RangePicker
          className={styles.optionInput}
          format="L"
          onChange={(value) => {
            if (value) {
              setDateStart(value[0]);
              setDateEnd(value[1]);
            } else {
              setDateStart(null);
              setDateEnd(null);
            }
          }}
          value={[dateStart, dateEnd]}
          allowClear
        />
        <Select
          className={styles.optionInput}
          onChange={(value) => setOperatorFilter(value)}
          placeholder={t('pages.form.operator.placeholder')}
          value={operatorFilter}
          allowClear
          loading={loading}
        >
          {data?.operators.map(mapList)}
        </Select>
        <Select
          className={styles.optionInput}
          onChange={(value) => setShiftLeaderFilter(value)}
          placeholder={t('pages.form.shiftLeader.placeholder')}
          value={shiftLeaderFilter}
          allowClear
          loading={loading}
        >
          {data?.shiftLeaders.map(mapList)}
        </Select>
        {isEngineerRequired && (
          <Select
            className={styles.optionInput}
            onChange={(value) => setShiftEngineerFilter(value)}
            placeholder={t('pages.form.shiftEngineer.placeholder')}
            value={shiftEngineerFilter}
            allowClear
            loading={loading}
          >
            {data?.shiftEngineers.map(userMapList)}
          </Select>
        )}
        {isManagerRequired && (
          <Select
            className={styles.optionInput}
            onChange={(value) => setShiftManagerFilter(value)}
            placeholder={t('pages.form.shiftManager.placeholder')}
            value={shiftManagerFilter}
            allowClear
            loading={loading}
          >
            {data?.shiftManagers.map(userMapList)}
          </Select>
        )}
        <Select
          className={styles.optionInput}
          onChange={(value) => setShift(value)}
          placeholder={t('pages.form.shift.placeholder')}
          value={shift}
          allowClear
          loading={false}
        >
          {data?.shift.map(mapList)}
        </Select>
      </Flex>
      <Button onClick={resetFilters} className="button-center-no-margin">
        {t('pages.report.list.filterNotFound.button')}
      </Button>
      <ReportList
        loading={loading}
        refetchList={refetch}
        shiftReports={data?.shiftReports}
        paginationMeta={{
          shiftReportCount: data?.shiftReportAggregate.aggregate?.count || 0,
        }}
        emptyListComponent={emptyListComponent}
      />
      <Link to={Routes.ReportDashboard} className="button-center">
        <Button type="primary">{t('common.to_main_page')}</Button>
      </Link>
    </div>
  );
}

export default ReportListValidated;
