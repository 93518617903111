import dayjs from 'dayjs';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePerformanceMetricsControllerGetPerformanceMetrics } from 'generated/apiComponents';
import { useGetRingsMetricsQuery } from 'generated/graphql';
import {
  HUMAN_READABLE_FORMAT,
  MONTH_YEAR_FORMAT,
} from 'technical/string/formatter';
import { humanizeDuration } from 'technical/time-utils/humanize-duration';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

export const usePerformanceMetrics = () => {
  // No need of the return functions, just to watch the local changes
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  invariant(currentConstructionSite, 'No constructionSite id');

  const {
    data: speedPerformance,
    loading: speedPerformanceLoading,
    error: speedPerformanceError,
  } = useGetRingsMetricsQuery({
    variables: {
      constructionSiteId: currentConstructionSite.id,
    },
  });

  const {
    data: buildPerformance,
    isFetching: buildPerformanceLoading,
    error: buildPerformanceError,
  } = usePerformanceMetricsControllerGetPerformanceMetrics({
    queryParams: {
      constructionSiteId: currentConstructionSite.id,
    },
  });

  const loading = speedPerformanceLoading || buildPerformanceLoading;
  const error =
    speedPerformanceError?.message || buildPerformanceError?.payload;

  if (loading || !speedPerformance || !buildPerformance) {
    return { loading, error, metrics: null };
  }

  const { fastestExcavation, fastestRingPose } = speedPerformance;

  const fastestExcavationFormatted = {
    duration: isUndefinedOrNull(fastestExcavation[0]?.realExcavationDuration)
      ? '-'
      : humanizeDuration(fastestExcavation[0]?.realExcavationDuration),
    ringNumber: t('dashboard.performance.ringNumber', {
      ringNumber: fastestExcavation[0]?.ringNumber || '-',
    }),
  };

  const fastestRingPoseFormatted = {
    duration: isUndefinedOrNull(fastestRingPose[0]?.realBuildDuration)
      ? '-'
      : humanizeDuration(fastestRingPose[0]?.realBuildDuration),
    ringNumber: t('dashboard.performance.ringNumber', {
      ringNumber: fastestRingPose[0]?.ringNumber || '-',
    }),
  };

  const formatMonth = (date: string) => {
    return dayjs(date).format(MONTH_YEAR_FORMAT);
  };

  const formatWeek = (date: string) => {
    return t('common.dateRange', {
      startDate: dayjs(date).format(HUMAN_READABLE_FORMAT),
      endDate: dayjs(date).add(6, 'day').format(HUMAN_READABLE_FORMAT),
    });
  };

  const formatDay = (date: string) => {
    return dayjs(date).format(HUMAN_READABLE_FORMAT);
  };

  const metrics = {
    dayWithMostRingsBuilt: {
      ...buildPerformance.day,
      date: formatDay(buildPerformance.day.date),
    },
    weekWithMostRingsBuilt: {
      ...buildPerformance.week,
      date: formatWeek(buildPerformance.week.date),
    },
    monthWithMostRingsBuilt: {
      ...buildPerformance.month,
      date: formatMonth(buildPerformance.month.date),
    },
    fastestExcavation: fastestExcavationFormatted,
    fastestPose: fastestRingPoseFormatted,
  };

  return { loading, error, metrics };
};
