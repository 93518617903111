import dayjs from 'dayjs';

import { ExcavationBuildTimeFilter } from 'business/production-and-performance/hooks/excavation-build-time-filters-context/types';

export const initialFilters: ExcavationBuildTimeFilter = {
  showTotalDurations: false,
  dateRange: [dayjs().subtract(1, 'week'), dayjs()],
  dayMode: 'shift',
  durationInterval: [0, null],
};
