import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePermissions } from 'business/contextProviders/usePermissions';
import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';

const RedirectionHome: React.FC = () => {
  const { currentConstructionSite } = useAppContext();
  const { modules } = usePermissions();

  if (!currentConstructionSite) {
    return <Navigate to={Routes.NO_CONSTRUCTION_SITE} />; // should be handled by router cf: noConstructionSite
  }

  const { userPermissions } = currentConstructionSite;

  // Filter out the Backoffice module, as it shouldn't count
  const filteredPermissions = userPermissions.filter(
    (permission) => permission.module !== Module_Enum.Backoffice,
  );

  // Check if the remaining modules only include ShiftReport
  const hasShiftReportOnly =
    filteredPermissions.length === 1 &&
    filteredPermissions[0].module === Module_Enum.ShiftReport;

  // If the site only has "Shift Report" redirect to ShiftReportDashboard
  if (hasShiftReportOnly) {
    if (modules.SHIFT_REPORT?.isConstructionSiteUser) {
      return <Navigate to={Routes.ReportUserHome} />;
    }
    return <Navigate to={Routes.ReportDashboard} />;
  }

  // Otherwise, redirect to the GlobalDashboard
  return <Navigate to={Routes.Dashboard} />;
};

export default RedirectionHome;
