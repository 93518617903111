import { Empty, Result } from 'antd';
import { useTranslation } from 'translations/hooks';

import { ConstructionSitePlanningFilter } from 'business/production-and-performance/components/construction-site-planning-filters';
import { ConstructionSitePlanningGraph } from 'business/production-and-performance/components/construction-site-planning-graph';
import Loader from 'ui/loader';

import { useGetFilteredRingsRealProgressionQuery } from './hook/use-get-filtered-and-paginated-rings-query';

interface Props {
  constructionSiteId: string;
  filters: ConstructionSitePlanningFilter;
}

export const ConstructionSitePlanningContainer = ({
  constructionSiteId,
  filters,
}: Props) => {
  const { t } = useTranslation();
  const { filteredData, loading, error, messageContextHolder } =
    useGetFilteredRingsRealProgressionQuery(constructionSiteId, filters);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Result status="warning" title={t('errors.error_generic')} />;
  }

  if (!filteredData || !filteredData.length) {
    return <Empty />;
  }

  return (
    <>
      {messageContextHolder}
      <ConstructionSitePlanningGraph data={filteredData} filters={filters} />
    </>
  );
};
