import { UploadOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Modal, Space, Upload } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import { RcFile } from 'antd/lib/upload';
import { useTranslation } from 'translations/hooks';

import { usePlanningControllerImportPlanning } from 'generated/apiComponents';
import { useApiContext } from 'generated/apiContext';
import {
  useDeleteProjectionMutation,
  useGetRingProjectionCountQuery,
} from 'generated/graphql';
import { checkFileLength } from 'technical/file/check-file-length';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import Button from 'ui/button';

interface ManagerControlsProps {
  constructionSiteId: string;
}

const maxFileSizeMB = 5;
const maxFileSizeInBytes = maxFileSizeMB * 1024 * 1024; // 5 MB in bytes

const checkXLSFile = (file: RcFile, onError: () => void) => {
  const isExcelMimeType =
    file.type === 'application/vnd.ms-excel' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const isExcelExtension =
    file.name.endsWith('.xls') || file.name.endsWith('.xlsx');

  const isExcel = isExcelMimeType && isExcelExtension;

  if (!isExcel && onError) {
    onError();
  }

  return isExcel || Upload.LIST_IGNORE;
};

export const ManagerControls: React.FC<ManagerControlsProps> = ({
  constructionSiteId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = useNotification();
  const { fetcherOptions } = useApiContext();

  const {
    data: projectionCountData,
    loading: countIsLoading,
    refetch,
  } = useGetRingProjectionCountQuery({
    variables: { constructionSiteId },
  });

  const { mutateAsync, isPending: isImportPending } =
    usePlanningControllerImportPlanning({
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['production-and-performance', 'planning'],
        });

        await refetch();
      },
    });

  const [deleteProjection, { loading: isDeleteProjectionLoading }] =
    useDeleteProjectionMutation({
      onCompleted: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['production-and-performance', 'planning'],
        });

        await refetch();
      },
    });

  const handleImport = async ({ file }: { file: string | Blob | RcFile }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('constructionSiteId', constructionSiteId);

    await mutateAsync(
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...fetcherOptions.headers,
        },
        // @ts-expect-error we expect the generated type to not recognise formData
        body: formData,
      },
      {
        onSuccess: () =>
          messageApi.success({
            message: t('productionAndPerformance.planning.import.success'),
          }),
        onError: (error) => {
          messageApi.error({
            duration: 0, // force manual close
            message: t('productionAndPerformance.planning.import.error', {
              // @ts-expect-error Front generated conflict error type do not match backend type
              context: error.stack?.error,
            }),
          });
        },
      },
    );
  };

  const constructionSiteHasProjection =
    !isUndefinedOrNull(projectionCountData?.ringProjection?.aggregate?.count) &&
    projectionCountData?.ringProjection?.aggregate?.count > 0;

  return (
    <Space>
      {contextHolder}
      <Button
        danger
        loading={isDeleteProjectionLoading}
        disabled={!constructionSiteHasProjection || countIsLoading}
        onClick={() => {
          Modal.confirm({
            title: t('productionAndPerformance.planning.delete.confirm.title'),
            content: t(
              'productionAndPerformance.planning.delete.confirm.content',
            ),
            okText: t(
              'productionAndPerformance.planning.delete.confirm.confirmDelete',
            ),
            cancelText: t(
              'productionAndPerformance.planning.delete.confirm.cancel',
            ),
            closable: false,
            okButtonProps: { danger: true },
            onOk: async () => {
              await deleteProjection({
                variables: {
                  constructionSiteId,
                },
              });
            },
            footer: (_, { OkBtn, CancelBtn }) => (
              <Space>
                <CancelBtn />
                <OkBtn />
              </Space>
            ),
          });
        }}
      >
        {t('productionAndPerformance.planning.delete.cta')}
      </Button>
      <Upload
        maxCount={1}
        disabled={isImportPending}
        multiple={false}
        showUploadList={false}
        accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        beforeUpload={(file) => {
          return [
            checkFileLength(
              file,
              () => {
                messageApi.error({
                  message: t(
                    'productionAndPerformance.planning.import.error_INVALID_FILE_SIZE',
                  ),
                });
              },
              maxFileSizeInBytes,
            ),
            checkXLSFile(file, () => {
              messageApi.error({
                message: t(
                  'productionAndPerformance.planning.import.error_INVALID_FILE_TYPE',
                ),
              });
            }),
          ].every((result) => result === true);
        }}
        customRequest={handleImport}
      >
        <Button
          type="primary"
          icon={<UploadOutlined />}
          loading={isImportPending}
        >
          {t('productionAndPerformance.planning.import.label')}
        </Button>
      </Upload>
    </Space>
  );
};
