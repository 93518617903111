import { Form, message, Modal } from 'antd';
import { Dayjs } from 'dayjs';
import { DurationUnitsObjectType } from 'dayjs/plugin/duration';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { UpdateRingForm } from 'business/production-and-performance/components/update-ring-form';
import { parseRingTimeToDate } from 'business/production-and-performance/services/ring-time';
import {
  GetRingsQuery,
  useUpdateParameterRingMutation,
} from 'generated/graphql';
import apolloClient from 'technical/graphql/client';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import {
  parseDurationInputValueToDurationIsoString,
  parseIsoDurationToDurationInputValue,
} from 'ui/duration-input/parse-iso-duration';

interface UpdateRingModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
  ring: GetRingsQuery['parameter_ring'][number] | null;
}

type FlattenedDuration = Pick<
  DurationUnitsObjectType,
  'hours' | 'minutes' | 'seconds'
>;

export interface UpdateRingFormValues {
  ringNumber: number;
  keyPosition?: number | null;
  length?: any | null;
  buildEndMetricPoint?: number | null;
  excavationStart?: Dayjs | null;
  excavationEnd?: Dayjs | null;
  realExcavationDuration: FlattenedDuration;
  buildStart?: Dayjs | null;
  buildEnd?: Dayjs | null;
  realBuildDuration: FlattenedDuration;
}
export const UpdateRingModal: React.FC<UpdateRingModalProps> = ({
  isModalVisible,
  closeModal,
  ring,
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation();
  const [form] = Form.useForm<UpdateRingFormValues>();
  const [updateParameterRingMutation] = useUpdateParameterRingMutation();

  const save = async (row: UpdateRingFormValues) => {
    try {
      await updateParameterRingMutation({
        variables: {
          id: ring?.id,
          updatedFields: {
            ...row,
            excavationStart: row.excavationStart?.toISOString(),
            excavationEnd: row.excavationEnd?.toISOString(),
            buildStart: row.buildStart?.toISOString(),
            buildEnd: row.buildEnd?.toISOString(),
            realExcavationDuration: parseDurationInputValueToDurationIsoString(
              row.realExcavationDuration,
            ),
            realBuildDuration: parseDurationInputValueToDurationIsoString(
              row.realBuildDuration,
            ),
          },
        },
        onCompleted: async () => {
          await apolloClient.reFetchObservableQueries();
          closeModal();
          messageApi.open({
            type: 'success',
            content: t('pages.ring.edit.modal.update.success'),
          });
        },
        onError: () => {
          messageApi.open({
            type: 'error',
            content: t('pages.ring.edit.modal.update.error'),
          });
        },
      });
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: t('pages.ring.edit.modal.update.error'),
      });
    }
  };

  useEffect(() => {
    if (ring) {
      form.setFieldsValue({
        ...ring,
        excavationStart: parseRingTimeToDate(ring.excavationStart),
        excavationEnd: parseRingTimeToDate(ring.excavationEnd),

        // TODO: Parse the received value to a number
        realExcavationDuration: parseIsoDurationToDurationInputValue(
          ring.realExcavationDuration,
        ),
        buildStart: parseRingTimeToDate(ring.buildStart),
        buildEnd: parseRingTimeToDate(ring.buildEnd),
        realBuildDuration: parseIsoDurationToDurationInputValue(
          ring.realBuildDuration,
        ),
      });
    }
  }, [form, ring]);

  return (
    <>
      {contextHolder}
      <Modal
        title={t('pages.ring.edit.modal.title', {
          ringNumber: ring?.ringNumber,
        })}
        open={isModalVisible}
        onOk={form.submit}
        onCancel={closeModal}
        destroyOnClose
      >
        {!isUndefinedOrNull(ring) ? (
          <UpdateRingForm form={form} save={save} />
        ) : null}
      </Modal>
    </>
  );
};
