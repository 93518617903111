import { Form, Space, Switch, SwitchProps } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { ShiftOrCalendarSelector } from 'business/production-and-performance/components/shift-or-calendar-selector';
import {
  ExcavationBuildTimeDispatchActionTypes,
  ExcavationBuildTimeFilter,
  useExcavationBuildTimeFiltersDispatch,
  useExcavationBuildTimeFiltersValue,
} from 'business/production-and-performance/hooks/excavation-build-time-filters-context';
import { DateRangeType } from 'technical/types';
import NumberRangeInput, { NumberRangeValue } from 'ui/form/numberRangeInput';
import TemporalitySelectInput, {
  DateSelectionTypeEnum,
} from 'ui/form/temporality-select-input';

import styles from './index.module.scss';

export const ExcavationBuildTimeFilters = () => {
  const { t } = useTranslation();

  const filters = useExcavationBuildTimeFiltersValue();
  const dispatch = useExcavationBuildTimeFiltersDispatch();

  const toggleShowTotalDurations: SwitchProps['onChange'] = () => {
    dispatch({
      type: ExcavationBuildTimeDispatchActionTypes.ToggleTotalDurations,
    });
  };

  const onDayModeChange = (e: RadioChangeEvent) => {
    const dayMode: 'shift' | 'calendar' = e.target.value;
    dispatch({
      type: ExcavationBuildTimeDispatchActionTypes.ChangeDayMode,
      dayMode,
    });
  };

  const onDateChange = (dateRange: DateRangeType) => {
    dispatch({
      type: ExcavationBuildTimeDispatchActionTypes.UpdateDateFilter,
      dateRange,
    });
  };

  const onDurationIntervalChange = (durationInterval: NumberRangeValue) => {
    dispatch({
      type: ExcavationBuildTimeDispatchActionTypes.UpdateDurationInterval,
      durationInterval,
    });
  };

  const [form] = Form.useForm<ExcavationBuildTimeFilter>();

  return (
    <>
      <Form<ExcavationBuildTimeFilter>
        layout={'inline'}
        form={form}
        initialValues={filters}
      >
        <Form.Item
          layout={'vertical'}
          name="dateRange"
          label={t('input.dateRange.label')}
        >
          <TemporalitySelectInput
            onChange={onDateChange}
            allowClear={false}
            allowEmpty={false}
            value={filters.dateRange}
            defaultDate={dayjs()}
            excludedOptions={[DateSelectionTypeEnum.Date]}
          />
        </Form.Item>
        <Form.Item<ExcavationBuildTimeFilter>
          label={t('productionAndPerformance.filters.modeSelection')}
          layout={'vertical'}
          name="dayMode"
        >
          <ShiftOrCalendarSelector
            onChange={onDayModeChange}
            value={filters.dayMode}
          />
        </Form.Item>

        <Form.Item
          layout={'vertical'}
          label={t(
            'productionAndPerformance.excavationBuildTime.filters.durationInterval.label',
          )}
          validateTrigger="onChange"
        >
          <NumberRangeInput
            values={filters.durationInterval}
            onChange={onDurationIntervalChange}
            leftInputProps={{
              min: 0,
              placeholder: t(
                'productionAndPerformance.excavationBuildTime.filters.durationInterval.start',
              ),
            }}
            rightInputProps={{
              placeholder: t(
                'productionAndPerformance.excavationBuildTime.filters.durationInterval.end',
              ),
            }}
            className={styles.durationInterval}
          />
        </Form.Item>

        <Space align="end">
          <Form.Item
            layout={'vertical'}
            label={t(
              'productionAndPerformance.excavationBuildTime.filters.toggleTotalAverages',
            )}
          >
            <Switch
              onChange={toggleShowTotalDurations}
              value={filters.showTotalDurations}
              size="small"
            />
          </Form.Item>
        </Space>
      </Form>
    </>
  );
};
