import { getLocale } from 'translations/locale';

import { useDailyAdvancementControllerRingPerShift } from 'generated/apiComponents';
import { useGetHighestMetricPointQuery } from 'generated/graphql';

export const useDailyAdvancement = ({
  today,
  constructionSiteId,
  distanceUnit,
}: {
  today: string;
  constructionSiteId: string;
  distanceUnit: string;
}) => {
  const {
    data: ringsPerShift,
    isFetching: isFetchingDailyRingPerShift,
    error: ringsPerShiftError,
  } = useDailyAdvancementControllerRingPerShift({
    queryParams: {
      startDate: today,
      endDate: today,
      constructionSiteId,
    },
  });

  const {
    data: lastMetricPointBeforeToday,
    loading: isFetchingLastMetricPointBeforeToday,
    error: lastMetricPointBeforeTodayError,
  } = useGetHighestMetricPointQuery({
    variables: {
      constructionSiteId: { _eq: constructionSiteId },
      productionDay: { _lt: today },
    },
  });

  const {
    data: todayHighestMetricPoint,
    loading: isFetchingTodayHighestMetricPoint,
    error: todayHighestMetricPointError,
  } = useGetHighestMetricPointQuery({
    variables: {
      constructionSiteId: { _eq: constructionSiteId },
      productionDay: { _eq: today },
    },
  });

  const loading =
    isFetchingDailyRingPerShift ||
    isFetchingTodayHighestMetricPoint ||
    isFetchingLastMetricPointBeforeToday;

  const error =
    ringsPerShiftError?.payload ||
    todayHighestMetricPointError?.message ||
    lastMetricPointBeforeTodayError?.message;

  const lastMetricPoint =
    lastMetricPointBeforeToday?.parameter_ringWithProductionDay_aggregate
      .aggregate?.max?.buildEndMetricPoint ?? 0;

  const todayMetricPoint =
    todayHighestMetricPoint?.parameter_ringWithProductionDay_aggregate.aggregate
      ?.max?.buildEndMetricPoint ?? 0;

  const metricAdvancementInMeters = todayMetricPoint
    ? todayMetricPoint - lastMetricPoint
    : 0;

  const metersToFeet = (meters: number): number => {
    const METER_TO_FEET_FACTOR = 3.28084;
    return meters * METER_TO_FEET_FACTOR;
  };

  const formatDistance = (value: number, unit: 'meter' | 'foot'): string => {
    return new Intl.NumberFormat(getLocale(), {
      style: 'unit',
      unit,
      unitDisplay: 'short',
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    }).format(value);
  };

  const formatMetricAdvancement = (metricAdvancement: number): string => {
    if (!metricAdvancement) {
      return '0';
    }
    if (distanceUnit === 'm') {
      return formatDistance(metricAdvancement, 'meter');
    }

    return formatDistance(metersToFeet(metricAdvancement), 'foot');
  };
  const metricAdvancement = formatMetricAdvancement(metricAdvancementInMeters);

  const dailyAdvancement = {
    shift1Count: ringsPerShift?.shift1Count ?? 0,
    shift2Count: ringsPerShift?.shift2Count ?? 0,
    shift3Count: ringsPerShift?.shift3Count ?? 0,
    metricAdvancement,
  };
  return { error, loading, dailyAdvancement };
};
