import { PropsWithChildren, useContext, useReducer } from 'react';

import {
  ExcavationBuildTimeFiltersValueContext,
  ExcavationBuildTimeUpdateFiltersContext,
} from './contexts';
import { initialFilters } from './filter-values';
import { filtersReducer } from './reducer';
import {
  ExcavationBuildTimeDispatchActionTypes,
  ExcavationBuildTimeFilter,
} from './types';

const ExcavationBuildTimeFiltersProvider = ({
  children,
}: PropsWithChildren) => {
  const [filters, dispatchFilters] = useReducer(filtersReducer, initialFilters);

  return (
    <ExcavationBuildTimeFiltersValueContext.Provider value={filters}>
      <ExcavationBuildTimeUpdateFiltersContext.Provider value={dispatchFilters}>
        {children}
      </ExcavationBuildTimeUpdateFiltersContext.Provider>
    </ExcavationBuildTimeFiltersValueContext.Provider>
  );
};

function useExcavationBuildTimeFiltersValue() {
  return useContext(ExcavationBuildTimeFiltersValueContext);
}

function useExcavationBuildTimeFiltersDispatch() {
  return useContext(ExcavationBuildTimeUpdateFiltersContext);
}

export {
  ExcavationBuildTimeFiltersProvider,
  useExcavationBuildTimeFiltersValue,
  useExcavationBuildTimeFiltersDispatch,
  ExcavationBuildTimeDispatchActionTypes,
  type ExcavationBuildTimeFilter,
};
