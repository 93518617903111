import { Alert } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { DailyAdvancement } from 'business/dashboard/components/daily-advancement';
import { useDailyAdvancement } from 'business/dashboard/hooks/use-daily-advancement';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import Loader from 'ui/loader';

const DailyAdvancementContainer: React.FC = () => {
  const { currentConstructionSite } = useAppContext();
  const { t } = useTranslation();
  invariant(currentConstructionSite, 'No constructionSite id');

  const today = dayjs().startOf('day');

  const distanceUnit = currentConstructionSite.distanceUnit;

  const { loading, error, dailyAdvancement } = useDailyAdvancement({
    today: today.format(SIMPLE_DATE_FORMAT),
    constructionSiteId: currentConstructionSite.id,
    distanceUnit,
  });

  if (error) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }

  if (loading || isUndefinedOrNull(dailyAdvancement)) {
    return <Loader />;
  }

  return (
    <DailyAdvancement
      shift1Count={dailyAdvancement.shift1Count}
      shift2Count={dailyAdvancement.shift2Count}
      shift3Count={dailyAdvancement.shift3Count}
      builtRings={
        dailyAdvancement.shift1Count +
        dailyAdvancement.shift2Count +
        dailyAdvancement.shift3Count
      }
      metricAdvancement={dailyAdvancement.metricAdvancement}
    />
  );
};

export default DailyAdvancementContainer;
