import { SwapRightOutlined } from '@ant-design/icons';
import { InputNumber, Space, Typography } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames';

import { notEmpty } from 'technical/utils/not-empty';

import styles from './index.module.scss';

export type NumberRangeValue =
  | [number | null | undefined, number | null | undefined]
  | null;

export interface NumberRangeInputProps {
  values: NumberRangeValue;
  onChange: (values: NumberRangeValue) => void;
  leftInputProps?: InputNumberProps;
  leftTitle?: string;
  rightInputProps?: InputNumberProps;
  rightTitle?: string;
  errorMessage?: string;
  className?: string;
}

function NumberRangeInput({
  values,
  onChange,
  leftInputProps,
  leftTitle,
  rightInputProps,
  rightTitle,
  errorMessage,
  className,
}: Readonly<NumberRangeInputProps>) {
  const leftInputId = `leftInput-${leftTitle}`;
  const rightInputId = `rightInput-${rightTitle}`;

  return (
    <Space
      direction={'vertical'}
      className={classNames(styles.rangeInputContainer, className)}
    >
      <Space
        className={classNames(
          styles.rangeInput,
          styles.customPicker,
          //custom picker is duplicate of  'ant-input ant-picker ant-picker-range ant-picker-outlined',
        )}
      >
        <Space>
          {leftTitle ? (
            <label htmlFor={leftInputId}>
              <Typography.Text type="secondary">{leftTitle}</Typography.Text>
            </label>
          ) : null}
          <InputNumber
            className={styles.inputNumber}
            {...leftInputProps}
            variant={'borderless'}
            size={'small'}
            id={leftTitle ? leftInputId : undefined}
            value={notEmpty(values?.[0]) ? values[0] : undefined}
            onChange={(value) =>
              typeof value !== 'string'
                ? onChange([value, values ? values[1] : undefined])
                : onChange([undefined, values ? values[1] : undefined])
            }
          />
        </Space>
        <SwapRightOutlined
          className={
            styles.customPickerSeparator
            // duplicate of native ant class 'ant-picker-separator'
          }
        />
        <Space>
          {rightTitle ? (
            <label htmlFor={rightInputId}>
              <Typography.Text type="secondary">{rightTitle}</Typography.Text>
            </label>
          ) : null}
          <InputNumber
            {...rightInputProps}
            id={rightTitle ? rightInputId : undefined}
            value={notEmpty(values?.[1]) ? values[1] : undefined}
            variant={'borderless'}
            size={'small'}
            className={styles.inputNumber}
            onChange={(value) =>
              typeof value !== 'string'
                ? onChange([values ? values[0] : undefined, value])
                : onChange([values ? values[0] : undefined, undefined])
            }
          />
        </Space>
      </Space>
      {errorMessage ? (
        <Typography.Text type="danger">{errorMessage}</Typography.Text>
      ) : null}
    </Space>
  );
}

export default NumberRangeInput;
