import { RcFile } from 'antd/lib/upload';

export const checkFileLength = (
  file: RcFile,
  onError: () => void,
  maxFileSizeInBytes: number,
) => {
  if (file.size > maxFileSizeInBytes) {
    if (onError) {
      onError();
    }
    return false;
  }

  return true;
};
