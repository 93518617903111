import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import CardValue from 'business/dashboard/components/card-value';
import { PerformanceMetric } from 'generated/apiSchemas';

interface PerformanceProps {
  dayWithMostRingsBuilt: PerformanceMetric;
  weekWithMostRingsBuilt: PerformanceMetric;
  monthWithMostRingsBuilt: PerformanceMetric;
  fastestExcavation: {
    duration: string;
    ringNumber: string | number;
  };
  fastestPose: {
    duration: string;
    ringNumber: string | number;
  };
}

export const Performance: React.FC<PerformanceProps> = ({
  dayWithMostRingsBuilt,
  weekWithMostRingsBuilt,
  monthWithMostRingsBuilt,
  fastestExcavation,
  fastestPose,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} lg={12} xl={8}>
        <CardValue
          title={t('dashboard.performance.dayRingsBuilt')}
          value={dayWithMostRingsBuilt.count.toString()}
          footer={dayWithMostRingsBuilt.date}
        />
      </Col>
      <Col xs={24} lg={12} xl={8}>
        <CardValue
          title={t('dashboard.performance.weekRingsBuilt')}
          value={weekWithMostRingsBuilt.count.toString()}
          footer={weekWithMostRingsBuilt.date}
        />
      </Col>
      <Col xs={24} lg={24} xl={8}>
        <CardValue
          title={t('dashboard.performance.monthRingsBuilt')}
          value={monthWithMostRingsBuilt.count.toString()}
          footer={monthWithMostRingsBuilt.date}
        />
      </Col>

      <Col xs={24} lg={12}>
        <CardValue
          title={t('dashboard.performance.fastestExcavation')}
          value={fastestExcavation.duration}
          footer={fastestExcavation.ringNumber}
        />
      </Col>
      <Col xs={24} lg={12}>
        <CardValue
          title={t('dashboard.performance.fastestPose')}
          value={fastestPose.duration}
          footer={fastestPose.ringNumber}
        />
      </Col>
    </Row>
  );
};
