import dayjs from 'dayjs';
import React from 'react';

import { ProgressHistoryGraph } from 'business/dashboard/components/progress-history-graph';
import { GetProgressHistoryResponseDto } from 'generated/apiSchemas';

interface ProgressHistoryProps {
  progressHistory: GetProgressHistoryResponseDto;
}

export const ProgressHistory: React.FC<ProgressHistoryProps> = ({
  progressHistory,
}) => {
  return (
    <ProgressHistoryGraph
      progressHistoryGraphData={progressHistory.graphData.map(
        ({ date, ...rest }) => ({
          ...rest,
          date: dayjs(date).toDate(),
        }),
      )}
    />
  );
};
