import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { ConstructionSitePlanningContainer } from 'business/production-and-performance/components/construction-site-planning';
import {
  ConstructionSitePlanningFilter,
  ConstructionSitePlanningFilters,
} from 'business/production-and-performance/components/construction-site-planning-filters';
import { PlanningHeader } from 'business/production-and-performance/components/construction-site-planning-header';
import { ProdPerfPageTemplate } from 'business/production-and-performance/components/prod-perf-page-template';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

const PlanningPage = () => {
  const { t } = useTranslation();

  const { currentConstructionSiteId } = useAppContext();

  const [filters, setFilters] = useState<ConstructionSitePlanningFilter>({
    values: [null, null],
    withProjection: true,
  });

  const handleFilterChange = (newFilter: ConstructionSitePlanningFilter) => {
    setFilters(newFilter);
  };

  if (isUndefinedOrNull(currentConstructionSiteId)) {
    return null;
  }

  return (
    <ProdPerfPageTemplate
      title={t('productionAndPerformance.planning.title')}
      header={<PlanningHeader filters={filters} />}
      filters={
        <ConstructionSitePlanningFilters
          filters={filters}
          onFilterChange={handleFilterChange}
        />
      }
    >
      <ConstructionSitePlanningContainer
        constructionSiteId={currentConstructionSiteId}
        filters={filters}
      />
    </ProdPerfPageTemplate>
  );
};

export default PlanningPage;
