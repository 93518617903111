import { Col, Row } from 'antd';
import { useTranslation } from 'translations/hooks';

import CardValue from 'business/dashboard/components/card-value';

interface DailyAdvancementProps {
  shift1Count: number;
  shift2Count: number;
  shift3Count: number;
  builtRings: number;
  metricAdvancement: string;
}

export const DailyAdvancement: React.FC<DailyAdvancementProps> = ({
  shift1Count,
  shift2Count,
  shift3Count,
  builtRings,
  metricAdvancement,
}) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} sm={12}>
        <CardValue
          title={t('dashboard.dailyAdvancement.excavated')}
          value={metricAdvancement}
          fullWidth
        />
      </Col>
      <Col xs={24} sm={12}>
        <CardValue
          title={t('dashboard.dailyAdvancement.ringsBuilt')}
          value={builtRings}
          fullWidth
        />
      </Col>

      <Col xs={24} lg={8}>
        <CardValue
          title={t('dashboard.dailyAdvancement.post', { name: 1 })}
          value={shift1Count}
          fullWidth
        />
      </Col>
      <Col xs={24} lg={8}>
        <CardValue
          title={t('dashboard.dailyAdvancement.post', { name: 2 })}
          value={shift2Count}
          fullWidth
        />
      </Col>
      <Col xs={24} lg={8}>
        <CardValue
          title={t('dashboard.dailyAdvancement.post', { name: 3 })}
          value={shift3Count}
          fullWidth
        />
      </Col>
    </Row>
  );
};
