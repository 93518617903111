import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { TFunction, useTranslation } from 'translations/hooks';

import { formatGraphDateAxisLabel } from 'business/production-and-performance/services/graph-date-format';

type RingPerDayGraphProps = {
  ringPerDayGraphData: {
    date: Date;
    shift1Count?: number;
    shift2Count?: number;
    shift3Count?: number;
    count?: number;
  }[];
  dayMode: 'shift' | 'calendar';
};

const ringPerDayShiftGraphSeriesOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift1Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 1,
      }), //'Shift 1',
      stacked: true,
    },
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift2Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 2,
      }),
      stacked: true,
    },
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift3Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 3,
      }),
      stacked: true,
    },
  ] satisfies AgCartesianSeriesOptions[];

const ringPerDayCalendarGraphSeriesOptions = () =>
  [
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'count',
    },
  ] satisfies AgCartesianSeriesOptions[];

const ringPerDayGraphAxesOptions = () =>
  [
    {
      type: 'time',
      position: 'bottom',
      nice: false,
      label: {
        formatter: ({ value }) => formatGraphDateAxisLabel(value),
      },
    },
    {
      type: 'number',
      position: 'left',
      nice: false,
      interval: {
        step: 1,
      },
    },
  ] satisfies AgCartesianAxisOptions[];

export function RingPerDayGraph({
  ringPerDayGraphData,
  dayMode,
}: RingPerDayGraphProps) {
  const { t } = useTranslation();

  const chartOptions = {
    data: ringPerDayGraphData,
    series:
      dayMode === 'shift'
        ? ringPerDayShiftGraphSeriesOptions(t)
        : ringPerDayCalendarGraphSeriesOptions(),
    axes: ringPerDayGraphAxesOptions(),
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
}
