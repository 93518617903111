import { Button, Flex, Tree } from 'antd';
import { Key } from 'antd/es/table/interface';
import Search from 'antd/lib/input/Search';
import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import GraphSetSideBar from 'business/data-analysis/components/graph-set-side-bar';
import { parameterFamilyKeyPrefix } from 'business/data-analysis/pages/graph/types';
import { ParameterFamiliesQuery } from 'generated/graphql';

import { useSearchParameters } from './hooks/searchParameters';

interface Props {
  parameterFamilies: ParameterFamiliesQuery['parameter_familyDef'];
  visible: boolean;
  onCloseParameterSidebar: () => void;
  addParametersToChart: (selectedParameters: React.Key[]) => void;
}

export const ParameterSideBar = ({
  parameterFamilies,
  visible,
  onCloseParameterSidebar,
  addParametersToChart,
}: Props) => {
  const { t } = useTranslation();

  const [selectedParameters, setSelectedParameters] = useState<React.Key[]>([]);

  const onSelectParameter = (
    selectedKeysValue: Key[] | { checked: Key[]; halfChecked: Key[] },
  ) => {
    let parametersWithoutFamilies: React.Key[];
    if (Array.isArray(selectedKeysValue)) {
      // families can be checked to select all parameters whithin it, but we don't add families to the graph
      parametersWithoutFamilies = selectedKeysValue.filter(
        (key) => !key.toString().includes(parameterFamilyKeyPrefix),
      );
    } else {
      parametersWithoutFamilies = selectedKeysValue.checked.filter(
        (key) => !key.toString().includes(parameterFamilyKeyPrefix),
      );
    }
    setSelectedParameters(parametersWithoutFamilies);
  };

  const onAddParametersToChart = (parametersToAdd: React.Key[]) => {
    addParametersToChart(parametersToAdd);
    setSelectedParameters([]);
    onCloseParameterSidebar();
  };

  const {
    expandedKeys,
    onExpand,
    baseTreeData,
    onChange,
    treeData,
    searchInputValue,
  } = useSearchParameters(parameterFamilies);

  return (
    <GraphSetSideBar
      title={t('dataAnalysis.labels.parameterList')}
      visible={visible}
      onClose={onCloseParameterSidebar}
      footer={
        <Flex justify="flex-end" gap={8}>
          <Button onClick={onCloseParameterSidebar}>
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => onAddParametersToChart(selectedParameters)}
            type="primary"
          >
            {t('dataAnalysis.actions.addParameters')}
          </Button>
        </Flex>
      }
    >
      <Flex vertical justify="space-between" gap={16}>
        <Search
          placeholder="Search"
          onChange={(event) => {
            onChange(event);
          }}
        />

        <Tree
          checkable
          onCheck={onSelectParameter}
          checkedKeys={selectedParameters}
          treeData={searchInputValue ? treeData : baseTreeData}
          defaultExpandParent={true}
          selectable={false}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
        />
      </Flex>
    </GraphSetSideBar>
  );
};
