import dayjs from 'dayjs';

export const humanizeDuration = (isoDuration: string): string => {
  const duration = dayjs.duration(isoDuration);
  const hours = Math.trunc(duration.asHours()); // rm under hours values

  const minutes = duration.minutes().toString().padStart(2, '0');

  return `${hours} h ${minutes} min`;
};
