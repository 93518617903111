import dayjs from 'dayjs';

export const formatGraphDateAxisLabel = (value: Date) => {
  // If interval is too small, Ag Chart display half day label
  // By returning undefined, we skip the display
  if (value instanceof Date && value.getHours() != 0) {
    return undefined;
  }
  return dayjs(value).format('ll');
};
