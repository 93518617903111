import useMessage from 'antd/es/message/useMessage';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { ConstructionSitePlanningFilter } from 'business/production-and-performance/components/construction-site-planning-filters';
import {
  PlanningControllerGetPlanningQueryParams,
  usePlanningControllerGetPlanning,
} from 'generated/apiComponents';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { notEmpty } from 'technical/utils/not-empty';

export const useGetFilteredRingsRealProgressionQuery = (
  constructionSiteId: string,
  filters: ConstructionSitePlanningFilter,
) => {
  const { t } = useTranslation();

  const [messageApi, contextHolder] = useMessage();
  const [startDate, endDate] = filters.values ?? [null, null];

  const queryParams: PlanningControllerGetPlanningQueryParams = {
    constructionSiteId,
    withProjection: filters.withProjection,
  };

  if (startDate) {
    queryParams.startDate = startDate?.format(SIMPLE_DATE_FORMAT);
  }

  if (endDate) {
    queryParams.endDate = endDate?.format(SIMPLE_DATE_FORMAT);
  }

  const {
    data: result,
    isLoading,
    error,
  } = usePlanningControllerGetPlanning(
    {
      queryParams,
    },
    {
      select: (res) => ({
        data: res.data.map((item) => ({
          ...item,
          date: dayjs(item.date).toDate(),
        })),
      }),
    },
  );

  useEffect(() => {
    if (error) {
      messageApi.open({
        type: 'error',
        content: t('errors.fetching_error'),
      });
    }
  }, [error, messageApi, t]);

  const filteredData = result?.data.filter(
    (
      ring,
    ): ring is { highestRing: number; date: Date; projectedRing: number } =>
      notEmpty(ring) && notEmpty(ring.date),
  );

  return {
    filteredData,
    loading: isLoading,
    error,
    messageContextHolder: contextHolder,
  };
};
