import { DateRangeType } from 'technical/types';
import { NumberRangeValue } from 'ui/form/numberRangeInput';

export enum ExcavationBuildTimeDispatchActionTypes {
  ToggleTotalDurations = 'toggleTotalDurations',
  UpdateDateFilter = 'updateDateFilter',
  ChangeDayMode = 'changeDayMode',
  UpdateDurationInterval = 'updateDurationInterval',
}

export type ActionType =
  | {
      type: ExcavationBuildTimeDispatchActionTypes.ToggleTotalDurations;
    }
  | {
      type: ExcavationBuildTimeDispatchActionTypes.UpdateDateFilter;
      dateRange: DateRangeType;
    }
  | {
      type: ExcavationBuildTimeDispatchActionTypes.ChangeDayMode;
      dayMode: 'calendar' | 'shift';
    }
  | {
      type: ExcavationBuildTimeDispatchActionTypes.UpdateDurationInterval;
      durationInterval: NumberRangeValue;
    };

export interface ExcavationBuildTimeFilter {
  showTotalDurations: boolean;
  dateRange: DateRangeType;
  dayMode: 'shift' | 'calendar';
  durationInterval: NumberRangeValue;
}
