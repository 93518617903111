import dayjs from 'dayjs';
import { Duration, DurationUnitsObjectType } from 'dayjs/plugin/duration';

import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

import { DurationInputValue } from './types';

const DEFAULT_DURATION_INPUT_VALUE = {
  hours: 0,
  minutes: 0,
  seconds: 0,
} satisfies DurationInputValue;

/**
 * Convert everything larger than hours into hours (days, weeks, etc.)
 * @param duration
 */
function flattenDuration(duration: Duration) {
  const hours = Math.trunc(duration.asHours() ?? 0); // This converts all larger units to hours
  const minutes = duration.minutes() ?? 0;
  const seconds = duration.seconds() ?? 0;

  return {
    hours,
    minutes,
    seconds,
  };
}

export const parseIsoDurationToDurationInputValue = (
  isoDurationString: string | null | undefined,
) => {
  if (isUndefinedOrNull(isoDurationString)) {
    return DEFAULT_DURATION_INPUT_VALUE;
  }

  const duration = dayjs.duration(isoDurationString);

  if (!dayjs.isDuration(duration)) {
    return DEFAULT_DURATION_INPUT_VALUE;
  }

  return flattenDuration(duration);
};

export const parseDurationInputValueToDurationIsoString = (
  duration: DurationUnitsObjectType,
) => {
  return dayjs.duration(duration).toISOString();
};
