import {
  BarChartOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  LayoutOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePermissions } from 'business/contextProviders/usePermissions';
import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';
import ListIcon from 'ui/icons/list';
import PlotterIcon from 'ui/icons/plotter';
import ShiftReportIcon from 'ui/icons/shift-report';
import MenuColumn from 'ui/navBar/components/menu/menu-column';
import MenuContent from 'ui/navBar/components/menu/menu-content';
import MenuEntry from 'ui/navBar/components/menu/menu-entry';
import MenuSection from 'ui/navBar/components/menu/menu-section';

import style from './index.module.scss';

const ShiftReportPermissions = [Module_Enum.ShiftReport];
const DataAnalysisPermissions = [Module_Enum.DataAnalysis];
const prodPerfPermissions = [Module_Enum.ProdPerf];

const allPermissions = [
  ...ShiftReportPermissions,
  ...DataAnalysisPermissions,
  ...prodPerfPermissions,
];

const ReportingSectionPermissions = [...ShiftReportPermissions];
const DataAnalysisSectionPermissions = [...DataAnalysisPermissions];
const prodPerfSectionPermissions = [...prodPerfPermissions];

const MenuContentPermissions = [
  ...ReportingSectionPermissions,
  ...DataAnalysisSectionPermissions,
  ...prodPerfSectionPermissions,
];

interface SectionProps {
  onClick: () => void;
}

const DataAnalysisSection = ({ onClick }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <MenuSection
      title={t('user.menu.sections.dataAnalysis.title')}
      permissions={DataAnalysisSectionPermissions}
    >
      <MenuEntry
        route={Routes.DataAnalysisModeSelection}
        icon={<PlotterIcon />}
        title={t('user.menu.sections.dataAnalysis.plotter.title')}
        description={t('user.menu.sections.dataAnalysis.plotter.description')}
        permissions={DataAnalysisPermissions}
        onClick={onClick}
      />
    </MenuSection>
  );
};

const ReportingSection = ({ onClick }: SectionProps) => {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const { modules } = usePermissions();

  if (!currentConstructionSite) {
    return <Navigate to={Routes.NO_CONSTRUCTION_SITE} />; // should be handled by router cf: noConstructionSite
  }

  let link = Routes.ReportDashboard;
  if (modules.SHIFT_REPORT?.isConstructionSiteUser) {
    link = Routes.ReportUserHome;
  }

  return (
    <MenuSection
      title={t('user.menu.sections.reporting.title')}
      permissions={ReportingSectionPermissions}
    >
      <MenuEntry
        route={link}
        icon={<ShiftReportIcon />}
        title={t('user.menu.sections.reporting.shiftReport.title')}
        description={t('user.menu.sections.reporting.shiftReport.description')}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
    </MenuSection>
  );
};

const ProdPerfSection = ({ onClick }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <MenuSection
      title={t('user.menu.sections.prodPerf.title')}
      permissions={ReportingSectionPermissions}
    >
      <MenuEntry
        route={Routes.ProdPerfRingPerDay}
        icon={<BarChartOutlined className={style.icon} />}
        title={t('user.menu.sections.prodPerf.ringPerDay.title')}
        description={t('user.menu.sections.prodPerf.ringPerDay.description')}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
      <MenuEntry
        route={Routes.ProdPerfExcavationBuildTime}
        icon={<ClockCircleOutlined className={style.icon} />}
        title={t('user.menu.sections.prodPerf.excavationBuildTime.title')}
        description={t(
          'user.menu.sections.prodPerf.excavationBuildTime.description',
        )}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
      <MenuEntry
        route={Routes.ProdPerfRingList}
        icon={<ListIcon />}
        title={t('user.menu.sections.prodPerf.ringList.title')}
        description={t('user.menu.sections.prodPerf.ringList.description')}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
      <MenuEntry
        route={Routes.ProdPerfPlanning}
        icon={<CalendarOutlined className={style.icon} />}
        title={t('user.menu.sections.prodPerf.planning.title')}
        description={t('user.menu.sections.prodPerf.planning.description')}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
    </MenuSection>
  );
};

const DashboardEntry: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();

  const { currentConstructionSite } = useAppContext();
  const { modules } = usePermissions();

  if (!currentConstructionSite) {
    return null;
  }

  const { userPermissions } = currentConstructionSite;

  const filteredPermissions = userPermissions.filter(
    (permission) => permission.module !== Module_Enum.Backoffice,
  );

  const hasShiftReportOnly =
    filteredPermissions.length === 1 &&
    filteredPermissions[0].module === Module_Enum.ShiftReport;

  if (hasShiftReportOnly && modules.SHIFT_REPORT?.isConstructionSiteUser) {
    return null;
  }

  return (
    <MenuEntry
      route={Routes.Dashboard}
      title={t('user.menu.dashboard.title')}
      icon={<LayoutOutlined className={style.icon} />}
      description={t('user.menu.dashboard.description')}
      permissions={allPermissions}
      onClick={onClick}
    />
  );
};

const Menu = () => {
  const [open, setOpen] = useState(false);
  const { rawPermissions } = usePermissions();

  const filteredPermissions = rawPermissions.filter(
    (permission) => permission.module !== Module_Enum.Backoffice,
  );

  if (
    filteredPermissions.length === 1 &&
    filteredPermissions[0].module === Module_Enum.ShiftReport
  ) {
    return null;
  }

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <MenuContent
      permissions={MenuContentPermissions}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Space direction="vertical">
        <MenuColumn permissions={allPermissions}>
          <DashboardEntry onClick={hide} />
        </MenuColumn>
        <MenuColumn permissions={DataAnalysisSectionPermissions}>
          <DataAnalysisSection onClick={hide} />
        </MenuColumn>
      </Space>

      <MenuColumn permissions={ReportingSectionPermissions}>
        <ReportingSection onClick={hide} />
      </MenuColumn>
      <MenuColumn permissions={prodPerfSectionPermissions}>
        <ProdPerfSection onClick={hide} />
      </MenuColumn>
    </MenuContent>
  );
};

export default Menu;
