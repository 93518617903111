import { UseFilters } from 'business/data-analysis/components/filterCard/hooks/use-filters';
import { PlotterMode } from 'business/data-analysis/constants';

export const isFetchingDisabled = ({
  isFetching,
  atLeastOneParameterIsSelected,
  filters,
  mode,
}: {
  isFetching: boolean;
  atLeastOneParameterIsSelected: boolean;
  filters: ReturnType<UseFilters>['filters'];
  mode: PlotterMode;
}) => {
  if (isFetching) {
    return true;
  }

  if (!atLeastOneParameterIsSelected) {
    return true;
  }

  if (
    mode === PlotterMode.RING &&
    (!filters.ringFilters ||
      filters.ringFilters?.[0] === null ||
      filters.ringFilters?.[1] === null)
  ) {
    return true;
  }

  return false;
};
