import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  FilterByRangesInputs,
  RangeFilters,
} from 'business/production-and-performance/components/filter-by-ranges-inputs';
import { ProdPerfPageTemplate } from 'business/production-and-performance/components/prod-perf-page-template';
import { RingsTableContainer } from 'business/production-and-performance/components/ring-table-container';
import { RingTableHeader } from 'business/production-and-performance/components/ring-table-header';
import { FilterCard } from 'ui/filter-card';
import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';

export const RingTablePage = () => {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();

  const [filters, setFilters] = useState<RangeFilters>({
    filterType: 'date',
    values: [null, null],
  });

  if (currentConstructionSite === null) {
    return null;
  }

  const handleFilterChange = (newFilter: RangeFilters) => {
    setFilters(newFilter);
  };

  return (
    <ProdPerfPageTemplate
      title={t('pages.ring.title')}
      filters={
        <FilterCard>
          <FilterByRangesInputs
            onFilterChange={handleFilterChange}
            filter={filters}
            temporalitySelectInputProps={{
              excludedOptions: [DateSelectionTypeEnum.Date],
            }}
          />
        </FilterCard>
      }
      header={<RingTableHeader filters={filters} />}
    >
      <RingsTableContainer
        constructionSiteId={currentConstructionSite.id}
        filters={filters}
      />
    </ProdPerfPageTemplate>
  );
};

export default RingTablePage;
