import { Maybe } from 'generated/graphql';

export const SIMPLE_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_HOUR_MINUTE_FORMAT = 'HH:mm';
export const FULL_LOCALIZED_DATE_AND_TIME_FORMAT = 'L LT';
export const MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const HUMAN_READABLE_FORMAT = 'DD MMMM YYYY';

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatUserName = ({
  firstName,
  lastName,
}: {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
}) => (firstName && lastName ? `${firstName} ${lastName}` : '');
