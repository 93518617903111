import React from 'react';
import { Navigate } from 'react-router-dom';

import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';

import { useAppContext } from './contextProviders/useAppContext';
import { usePermissions } from './contextProviders/usePermissions';
import { ROLES } from './user/services/config';
import { userHasRole } from './user/services/permissions';

interface ProtectedRouteWithPermissionsProps {
  allowedRoles?: ROLES[];
  allowedModule?: Module_Enum;
  redirectPath?: string;
  children: React.ReactNode;
}

const DEFAULT_NOT_CONNECTED_ROUTE = Routes.App;

const ProtectedRouteWithPermissions: React.FC<
  ProtectedRouteWithPermissionsProps
> = ({
  allowedRoles = [],
  allowedModule,
  redirectPath = Routes.NOT_FOUND,
  children,
}) => {
  const { user, currentConstructionSite } = useAppContext();
  const { isConnected } = useAppContext();
  const { isDataManager } = usePermissions();

  if (!isConnected) {
    return <Navigate to={DEFAULT_NOT_CONNECTED_ROUTE} />;
  }

  if (isDataManager) {
    // data manager have all access
    return <>{children}</>;
  }

  const validRole = allowedRoles.some((role) =>
    userHasRole(role, currentConstructionSite, user, allowedModule),
  );

  if (validRole) {
    return <>{children}</>;
  }

  return <Navigate to={redirectPath} />;
};
export default ProtectedRouteWithPermissions;
