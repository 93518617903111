import { PlotterMode } from 'business/data-analysis/constants';
import { DataPoint } from 'business/data-analysis/pages/graph/types';
import { formatParameterName } from 'business/data-analysis/services/parameter-name-differentiation';
import {
  GraphByPeriodResponseDto,
  GraphByRingResponseDto,
  GraphStateDto,
  ParameterDto,
} from 'generated/apiSchemas';

const buildGraphStates = (
  graphs: GraphStateDto[],
  dataByParameter: Record<string, ParameterDto['values'][number][]>,
  mode: PlotterMode,
): GraphStateDto[] => {
  return graphs.map((graph) => {
    const updatedParameters = graph.parameters.map((parameter) => {
      const parameterName =
        mode === PlotterMode.RING
          ? formatParameterName(parameter)
          : parameter.name;

      if (dataByParameter[parameterName]) {
        return {
          ...parameter,
          values: dataByParameter[parameterName],
        };
      }
      return parameter;
    });

    return {
      ...graph,
      parameters: updatedParameters,
    };
  });
};

export const formatGraphByPeriodData = (
  data: GraphByPeriodResponseDto,
  graphs: GraphStateDto[],
): GraphStateDto[] => {
  const modeKeys = ['date'];

  // separate each parameter data in its own key
  const dataByParameter: Record<string, ParameterDto['values'][number][]> = {};

  data.values.forEach((point: DataPoint) => {
    const parameterKeys = Object.keys(point).filter(
      (key) => !modeKeys.includes(key),
    );
    parameterKeys.forEach((parameterKey) => {
      const dataPointForThisParameter = { ...point };
      Object.keys(dataPointForThisParameter).forEach((keyForOtherParameter) => {
        // clean other parameter data
        if (
          keyForOtherParameter !== parameterKey &&
          !modeKeys.includes(keyForOtherParameter)
        ) {
          delete dataPointForThisParameter[keyForOtherParameter];
        }
      });
      if (dataByParameter[parameterKey]) {
        dataByParameter[parameterKey].push(dataPointForThisParameter);
      } else {
        dataByParameter[parameterKey] = [dataPointForThisParameter];
      }
    });
  });

  return buildGraphStates(graphs, dataByParameter, PlotterMode.TIME_PERIOD);
};

export const formatGraphByRingData = (
  data: GraphByRingResponseDto,
  graphs: GraphStateDto[],
): GraphStateDto[] => {
  // separate each parameter data in its own key
  const dataByParameter: Record<string, ParameterDto['values'][number][]> = {};

  data.values.forEach((point) => {
    const parameterName = point.parameterName;

    const dataPointForThisParameterAndAgg = {
      ring: point.ring,
      [parameterName]: Number(point.value),
    };

    if (!dataByParameter[parameterName]) {
      dataByParameter[parameterName] = [];
    }

    dataByParameter[parameterName].push(dataPointForThisParameterAndAgg);
  });

  return buildGraphStates(graphs, dataByParameter, PlotterMode.RING);
};

export const removeGraphSetParameterValues = (graphs: GraphStateDto[]) => {
  return graphs.map((graph) => {
    return {
      ...graph,
      parameters: graph.parameters.map((parameter) => ({
        ...parameter,
        values: [],
      })),
    };
  });
};
