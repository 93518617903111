import dayjs from 'dayjs';

import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

/**
 * This function must be equivalent to the back function `formatRingDuration`
 * in back/custom-server/src/business/production-and-performance/production-and-performance-helper.service.ts
 * */
export const parseAndFormatRingDuration = (
  durationString: string | null | undefined,
) => {
  if (isUndefinedOrNull(durationString)) {
    return '-';
  }

  const duration = dayjs.duration(durationString);
  if (!dayjs.isDuration(duration)) {
    return '-';
  }

  const hours = Math.trunc(duration.asHours()); // rm under hours values

  const minutes = duration.minutes().toString().padStart(2, '0');

  const formattedDuration = `${hours}:${minutes}`;

  return formattedDuration;
};
