import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';

import { formatGraphDateAxisLabel } from 'business/production-and-performance/services/graph-date-format';

type ProgressHistoryGraphProps = {
  progressHistoryGraphData: {
    date: Date;
    count: number;
  }[];
};

const progressHistoryGraphSeriesOptions = () =>
  [
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'count',
    },
  ] satisfies AgCartesianSeriesOptions[];

const progressHistoryGraphAxesOptions = () =>
  [
    {
      type: 'time',
      position: 'bottom',
      nice: false,
      label: {
        formatter: ({ value }) => formatGraphDateAxisLabel(value),
      },
    },
    {
      type: 'number',
      position: 'left',
      nice: false,
      interval: {
        step: 1,
      },
    },
  ] satisfies AgCartesianAxisOptions[];

export function ProgressHistoryGraph({
  progressHistoryGraphData,
}: ProgressHistoryGraphProps) {
  const chartOptions = {
    data: progressHistoryGraphData,
    axes: progressHistoryGraphAxesOptions(),
    series: progressHistoryGraphSeriesOptions(),
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
}
