import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RadioChangeEvent } from 'antd/es/radio';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import { ShiftOrCalendarSelector } from 'business/production-and-performance/components/shift-or-calendar-selector';
import { DateRangeType } from 'technical/types';
import { FilterCard } from 'ui/filter-card';
import TemporalitySelectInput, {
  DateSelectionTypeEnum,
} from 'ui/form/temporality-select-input';

export interface RingPerDayFilter {
  values: DateRangeType;
  dayMode: 'shift' | 'calendar';
}

interface RingPerDayFiltersProps {
  onFilterChange: (value: RingPerDayFilter) => void;
  filters: RingPerDayFilter;
}

export const RingPerDayFilters: React.FC<RingPerDayFiltersProps> = ({
  onFilterChange,
  filters,
}) => {
  const { t } = useTranslation();

  const onDateChange = (values: DateRangeType) => {
    onFilterChange({ ...filters, values });
  };

  const ondDayModeChange = (e: RadioChangeEvent) => {
    const dayMode: 'shift' | 'calendar' = e.target.value;
    onFilterChange({ ...filters, dayMode });
  };

  const [form] = useForm<RingPerDayFilter>();

  return (
    <FilterCard>
      <Form<RingPerDayFilter>
        layout={'inline'}
        form={form}
        initialValues={filters}
      >
        <Form.Item<RingPerDayFilter>
          label={t('input.dateRange.label')}
          layout={'vertical'}
          name="values"
        >
          <TemporalitySelectInput
            onChange={onDateChange}
            allowClear={false}
            allowEmpty={false}
            value={filters.values}
            defaultDate={dayjs()}
            excludedOptions={[DateSelectionTypeEnum.Date]}
          />
        </Form.Item>
        <Form.Item<RingPerDayFilter>
          label={t('productionAndPerformance.filters.modeSelection')}
          layout={'vertical'}
          name="dayMode"
        >
          <ShiftOrCalendarSelector
            onChange={ondDayModeChange}
            value={filters.dayMode}
          />
        </Form.Item>
      </Form>
    </FilterCard>
  );
};
