import { ExportOutlined } from '@ant-design/icons';
import { message } from 'antd';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { RangeFilters } from 'business/production-and-performance/components/filter-by-ranges-inputs';
import config from 'config';
import {
  useRingListControllerGenerateRingListByDateExport,
  useRingListControllerGenerateRingListByRingExport,
} from 'generated/apiComponents';
import { downloadFile } from 'technical/print';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import Button from 'ui/button';

interface Props {
  filters: RangeFilters;
}

export const RingTableHeader = ({ filters }: Props) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const { currentConstructionSiteId } = useAppContext();
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const { mutate: generateExportPerDate } =
    useRingListControllerGenerateRingListByDateExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  const { mutate: generateExportPerRing } =
    useRingListControllerGenerateRingListByRingExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  const onClick = () => {
    if (filters.filterType === 'date') {
      const [startDate, endDate] = filters.values ?? [null, null];
      generateExportPerDate({
        body: {
          constructionSiteId: currentConstructionSiteId,
          // start and end date should not be null as we force to always have values, but input type say it can be null
          startDate: startDate?.format(SIMPLE_DATE_FORMAT),
          endDate: endDate?.format(SIMPLE_DATE_FORMAT),
          dayMode: 'calendar',
          locale: getLocale(),
        },
      });
    }

    if (filters.filterType === 'ring') {
      const [startRing, endRing] = filters.values ?? [null, null];
      generateExportPerRing({
        body: {
          constructionSiteId: currentConstructionSiteId,
          // start and end date should not be null as we force to always have values, but input type say it can be null
          startRing: startRing ?? undefined,
          endRing: endRing ?? undefined,
          locale: getLocale(),
        },
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Button icon={<ExportOutlined />} iconPosition="end" onClick={onClick}>
        {t('productionAndPerformance.excavationBuildTime.header.export')}
      </Button>
    </>
  );
};
