import { Form, Space, Switch, SwitchProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import { DateRangeType } from 'technical/types';
import { FilterCard } from 'ui/filter-card';
import TemporalitySelectInput, {
  DateSelectionTypeEnum,
} from 'ui/form/temporality-select-input';

export interface ConstructionSitePlanningFilter {
  values: DateRangeType;
  withProjection: boolean;
}

interface ConstructionSitePlanningFiltersProps {
  onFilterChange: (value: ConstructionSitePlanningFilter) => void;
  filters: ConstructionSitePlanningFilter;
}

export const ConstructionSitePlanningFilters: React.FC<
  ConstructionSitePlanningFiltersProps
> = ({ onFilterChange, filters }) => {
  const { t } = useTranslation();
  const [form] = useForm<ConstructionSitePlanningFilter>();
  const onDateChange = (values: DateRangeType) => {
    onFilterChange({ ...filters, values });
  };

  const toggleShowProjections: SwitchProps['onChange'] = (newValue) => {
    onFilterChange({ ...filters, withProjection: newValue });
  };

  return (
    <FilterCard>
      <Form<ConstructionSitePlanningFilter>
        layout={'inline'}
        form={form}
        initialValues={filters}
      >
        <Form.Item<ConstructionSitePlanningFilter>
          label={t('input.dateRange.label')}
          layout={'vertical'}
          name="values"
        >
          <TemporalitySelectInput
            onChange={onDateChange}
            allowClear={true}
            allowEmpty={true}
            value={filters.values}
            excludedOptions={[DateSelectionTypeEnum.Date]}
          />
        </Form.Item>

        <Space align="end">
          <Form.Item
            layout={'vertical'}
            label={t('productionAndPerformance.filters.withProjections')}
          >
            <Switch
              onChange={toggleShowProjections}
              value={filters.withProjection}
              size="small"
            />
          </Form.Item>
        </Space>
      </Form>
    </FilterCard>
  );
};
