import { Outlet, RouteObject, createBrowserRouter } from 'react-router-dom';

import { useAppContext } from 'business/contextProviders/useAppContext';
import ErrorBoundaryForRouter from 'technical/error-boundary/error-boundary-router';

import {
  allModulesRoutes,
  commonRoutesProps,
  errorRoutes,
  homeAndDashboardRoutes,
  noConstructionSite,
} from './routes';

const createBrowserRouterWithErrorCatcher = (routes: RouteObject[]) => {
  return createBrowserRouter([
    {
      element: <Outlet />,
      errorElement: <ErrorBoundaryForRouter />,
      children: routes,
    },
  ]);
};

export const useAppRouter = () => {
  const { constructionSites } = useAppContext();

  if (constructionSites.length === 0) {
    return createBrowserRouterWithErrorCatcher([
      ...commonRoutesProps,
      ...noConstructionSite,
      ...errorRoutes,
    ]);
  }

  return createBrowserRouterWithErrorCatcher([
    ...commonRoutesProps,
    ...homeAndDashboardRoutes,
    ...allModulesRoutes,
    ...errorRoutes,
  ]);
};
