import { message, Result } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import ReportList, {
  DEFAULT_PAGE_LIMIT,
  DEFAULT_PAGE_NUMBER,
} from 'business/report/components/ReportList';
import Routes from 'config/routes';
import { useGetReportsBeingEditedQuery } from 'generated/graphql';
import useSearchParamQuery from 'technical/router/hooks/useSearchParamQuery';
import Button from 'ui/button';
import SectionTitle from 'ui/title/sectionTitle';

import styles from './index.module.scss';

function ReportListInProgressPage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const searchParamsQuery = useSearchParamQuery();

  const { data, loading, error, refetch } = useGetReportsBeingEditedQuery({
    variables: {
      limit: DEFAULT_PAGE_LIMIT,
      offset:
        (Number(searchParamsQuery.get('page') ?? DEFAULT_PAGE_NUMBER) - 1) *
        DEFAULT_PAGE_LIMIT,
      constructionSiteId: currentConstructionSite?.id,
    },
    fetchPolicy: 'network-only',
    skip: !currentConstructionSite?.id,
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  const emptyListComponent = (
    <Result status="success" title={t('pages.manager.pending.done')} />
  );

  return (
    <div className={classNames(styles.ReportListInProgressPage)}>
      <SectionTitle
        label={t('pages.manager.pending.title')}
        className={styles.title}
        leftButton={
          <Link
            to={{ pathname: Routes.ReportDashboard, search: '?tab=report' }}
          >
            <Button>{t('common.to_main_page')}</Button>
          </Link>
        }
      />
      <ReportList
        loading={loading}
        refetchList={refetch}
        shiftReports={data?.shiftReports}
        paginationMeta={{
          shiftReportCount: data?.shiftReportAggregate.aggregate?.count || 0,
        }}
        emptyListComponent={emptyListComponent}
      />
      <Link to={Routes.ReportDashboard} className="button-center">
        <Button type="primary">{t('common.to_main_page')}</Button>
      </Link>
    </div>
  );
}

export default ReportListInProgressPage;
