import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { Module_Enum } from 'generated/graphql';

import Page from './page';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  module?: Exclude<Module_Enum, Module_Enum.Backoffice> | 'DASHBOARD';
}

export function ConnectedPage(props: Props) {
  const { isConnected } = useAppContext();
  const { t } = useTranslation();
  const { module, ...pageProps } = props;

  const navTitle = t('module.header', { context: module });

  return (
    <Page {...pageProps} isConnected={isConnected} title={navTitle}>
      <Outlet />
    </Page>
  );
}

export default ConnectedPage;
