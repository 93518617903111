import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppContext } from 'business/contextProviders/useAppContext';
import Routes from 'config/routes';

import ConnectedPage from './layout/connectedPage';

export const DEFAULT_NOT_CONNECTED_ROUTE = Routes.App;
const DEFAULT_CONNECTED_ROUTE = Routes.Home;
/**
 * Redirect the user to the default not-connected route when the user
 * is not-connected and intend to access connected route
 */
export const ProtectedRoute: React.FC = () => {
  const { isConnected } = useAppContext();
  if (!isConnected) {
    return <Navigate to={DEFAULT_NOT_CONNECTED_ROUTE} />;
  }

  return (
    <ConnectedPage>
      <Outlet />
    </ConnectedPage>
  );
};

/**
 * Redirect the user to the default connected route when the user is
 * connected and intend to access not connected route
 */
export const AnonymousRoute: React.FC = () => {
  const { isConnected } = useAppContext();

  if (isConnected) {
    return <Navigate to={DEFAULT_CONNECTED_ROUTE} />;
  }

  return <Outlet />;
};
